//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ConfigurationTable from "eai-configurator-ui/components/configuration/table/ConfigurationTable";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import * as Utils from "eai-configurator-ui/components/Utils";
import "../main.css";
import ScanReader from "src/components/taps/scan/ScanReader";
import ResourceAssignmentTable from "src/components/taps/table/ResourceAssignmentTable";
import ActivityStepTable from "src/components/taps/table/ActivityStepTable";
import { useDidMountEffect } from "eai-configurator-ui/components/configuration/utils/ConfigurationComponentUtils";
import * as TapsUtils from "src/components/taps/table/TapsUtils";

const useStyles = makeStyles((theme) => ({
  expandedPanel: {
    backgroundColor: "#fbfbfb",
  },
}));

const calculateExpandedActivityKeys = (path) => {
  const expanded = [];
  const activities = Config.fromPath(path);
  activities.children.forEach((activity, index) => {
    // Expand the activity if there is at least one resource assignment or step
    if (
      activity.children.find((child) => child.key === "resourceAssignments")
        .children.length > 0 ||
      activity.children.find((child) => child.key === "steps").children.length >
        0
    ) {
      expanded.push(index);
    }
  });
  return expanded;
};
const ActivityTable = ({ path, status, setStatus, forceFetchActivities }) => {
  const classes = useStyles();
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [scanRequestPending, setScanRequestPending] = React.useState(false);
  const [manuallyEditedCellScan, setManuallyEditedCellScan] = React.useState(
    null
  );
  const [
    configurationRequestPending,
    setConfigurationRequestPending,
  ] = React.useState(false);

  useDidMountEffect(() => {
    if (forceFetchActivities) {
      fetchActivities();
    }
  }, [forceFetchActivities]);

  React.useEffect(() => {
    const isStatusLoading = status && Boolean(status.loading);
    const shouldStatusBeLoading =
      configurationRequestPending || scanRequestPending;
    if (isStatusLoading !== shouldStatusBeLoading) {
      const statusClone = { ...status };
      statusClone.loading = shouldStatusBeLoading;
      setStatus(statusClone);
    }
  }, [configurationRequestPending, scanRequestPending]);

  const activityPath = `${path}:activity`;
  const columns = TapsUtils.createActivityColumns(searchVisible);

  const handleStartCellEditing = (row, column, rowIndex, columnIndex) => {
    setIsEditing(true);
  };

  const handleEndCellEditing = (oldValue, newValue, row, column) => {
    setIsEditing(false);

    if (oldValue !== newValue) {
      const activityMarker = "O";
      setManuallyEditedCellScan({
        scan: TapsUtils.markerByField[column.dataField] + newValue,
        objectId: activityMarker + row.objectId,
      });
    }
  };

  const fetchActivities = () => {
    if (!configurationRequestPending) {
      setConfigurationRequestPending(true);
      Utils.fetchData("configuration/values")
        .then((data) => {
          Config.setConfigurationValues(data.values, data.defaults);
          setConfigurationRequestPending(false);
        })
        .catch(function (error) {
          setConfigurationRequestPending(false);
        });
    }
  };

  const handleScanRequestIssued = (scanRequest) => {
    setScanRequestPending(true);
  };

  const onScanResponseReceived = (scanResponse) => {
    setStatus(scanResponse);
    setScanRequestPending(false);
    fetchActivities();
  };

  const expandRow = {
    renderer: (row) => (
      <div>
        <ResourceAssignmentTable
          path={`${activityPath}[${row.dataIndex}]`}
          setManuallyEditedCellScan={setManuallyEditedCellScan}
          setIsEditing={setIsEditing}
        />
        <ActivityStepTable
          path={`${activityPath}[${row.dataIndex}]`}
          setManuallyEditedCellScan={setManuallyEditedCellScan}
          setIsEditing={setIsEditing}
        />
      </div>
    ),
    expandByColumnOnly: true,
    className: classes.expandedPanel,
    expanded: calculateExpandedActivityKeys(path),
  };
  const title = Config.getDefault(path).data.label;
  return (
    <div style={{ width: "100%" }} className="taps_activity_table">
      <ScanReader
        active={!isEditing}
        handleScanRequestIssued={handleScanRequestIssued}
        onScanResponseReceived={onScanResponseReceived}
        manualScan={manuallyEditedCellScan}
      />
      <ConfigurationTable
        path={path}
        childKey={"activity"}
        columns={columns}
        title={title}
        onStartEdit={handleStartCellEditing}
        afterSaveCell={handleEndCellEditing}
        expandRow={expandRow}
        hideAddButton
        rowStyle={TapsUtils.activityRowStyle}
        handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      />
    </div>
  );
};

export default ActivityTable;
