//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import "../main.css";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "30px",
  },
}));
const StatusBar = ({ status }) => {
  const classes = useStyles();
  const loading = status ? status.loading : false;
  const message = status ? status.message : "";
  const error = status
    ? status.hasOwnProperty("success") && !status.success
    : false;
  return (
    <div
      className={classes.root}
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {error && (
        <IconButton>
          <WarningIcon style={{ color: "orange" }} />
        </IconButton>
      )}
      {message}
      {loading && <CircularProgress style={{ marginLeft: "10px" }} size={20} />}
    </div>
  );
};

export default StatusBar;
