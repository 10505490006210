//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ConfigurationDatePicker from "eai-configurator-ui/components/configuration/base/ConfigurationDatePicker";
import { useSaveConfiguration } from "src/components/taps/save/ConfigurationSaveUtils";

const DateDialog = ({ path, open, handleClose }) => {
  const [forceSave, setForceSave] = React.useState(0);
  const saveConfiguration = useSaveConfiguration();

  React.useEffect(() => {
    if (forceSave) {
      issueSaveRequest();
    }
  }, [forceSave]);

  const issueSaveRequest = () => {
    saveConfiguration();
  };

  const handleChanged = () => {
    setForceSave(forceSave + 1);
  };

  return (
    <CloseableDialog
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth
      title={"Update Date"}
    >
      <DialogContent>
        <DialogContentText>Select update date to continue</DialogContentText>
        <p />
        <ConfigurationDatePicker
          path={path + ":updateDate"}
          textFieldProps={{
            onBlur: handleClose,
          }}
          handleChanged={handleChanged}
        />
      </DialogContent>
    </CloseableDialog>
  );
};
export default DateDialog;
