//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createCheckBoxEditorColumn,
  createDataIndexEditorColumn,
  createTextEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ConfigurationTable from "eai-configurator-ui/components/configuration/table/ConfigurationTable";
import "../main.css";
import * as TapsUtils from "src/components/taps/table/TapsUtils";

const ResourceAssignmentTable = ({
  path,
  setIsEditing,
  setManuallyEditedCellScan,
}) => {
  const [searchVisible, setSearchVisible] = React.useState(false);

  const rsrcPath = `${path}:resourceAssignments:resourceAssignment`;
  const columns = [
    createTextEditorColumn(rsrcPath + ":resourceId", searchVisible, {
      editable: false,
    }),
    createTextEditorColumn(rsrcPath + ":resourceName", searchVisible, {
      editable: false,
    }),
    createTextEditorColumn(rsrcPath + ":plannedUnits", searchVisible, {
      editable: false,
    }),
    createTextEditorColumn(rsrcPath + ":actualUnits", searchVisible, {
      style: TapsUtils.actualUnitsStyle,
      align: "right",
    }),
    createTextEditorColumn(rsrcPath + ":remainingUnits", searchVisible, {
      style: TapsUtils.remainingUnitsStyle,
      align: "right",
    }),
    createTextEditorColumn(rsrcPath + ":atCompletionUnits", searchVisible, {
      editable: false,
      align: "right",
    }),
    createTextEditorColumn(
      rsrcPath + ":resourceUnitsPercentComplete",
      searchVisible,
      {
        style: TapsUtils.unitsPercentCompleteStyle,
        align: "right",
      }
    ),
    createCheckBoxEditorColumn(rsrcPath + ":cancelled", searchVisible, {
      editable: false,
      align: "right",
      style: TapsUtils.cancelledStyle,
    }),
    createDataIndexEditorColumn(),
    {
      dataField: "objectId",
      hidden: true,
      type: "text",
    },
    {
      dataField: "modifiedFields",
      hidden: true,
    },
    {
      dataField: "active",
      hidden: true,
    },
  ];

  const handleStartCellEditing = (row, column, rowIndex, columnIndex) => {
    setIsEditing(true);
  };

  const handleEndCellEditing = (oldValue, newValue, row, column) => {
    setIsEditing(false);

    if (oldValue !== newValue) {
      const resourceAssignmentMarker = "A";
      setManuallyEditedCellScan({
        scan: TapsUtils.markerByField[column.dataField] + newValue,
        objectId: resourceAssignmentMarker + row.objectId,
      });
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <ConfigurationTable
        path={`${path}:resourceAssignments`}
        childKey={"resourceAssignment"}
        columns={columns}
        onStartEdit={handleStartCellEditing}
        afterSaveCell={handleEndCellEditing}
        hideAddButton
        rowStyle={TapsUtils.activityRowStyle}
        hideTableWhenEmpty
      />
    </div>
  );
};

export default ResourceAssignmentTable;
