//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import * as Utils from "eai-configurator-ui/components/Utils";
import "../main.css";

function isAlphanumeric(character) {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (character.match(letterNumber)) {
    return true;
  } else {
    return false;
  }
}

const ScanReader = ({
  active,
  handleScanRequestIssued,
  onScanResponseReceived,
  manualScan,
}) => {
  const [scanInput, setScanInput] = React.useState({});

  React.useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [scanInput, active]);

  React.useEffect(() => {
    if (manualScan && manualScan.scan) {
      postScan(manualScan);
    }
  }, [manualScan]);

  const postScan = (scanData) => {
    if (scanData.scan) {
      const request = {
        scan: scanData.scan,
      };
      if (scanData.objectId) {
        request.objectId = scanData.objectId;
      }
      if (handleScanRequestIssued) {
        handleScanRequestIssued(request);
      }
      Utils.fetchData("taps/postScan?request=" + JSON.stringify(request), {
        method: "post",
      })
        .then((data) => {
          console.log(data);
          if (onScanResponseReceived) {
            onScanResponseReceived(data);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (onScanResponseReceived) {
            onScanResponseReceived();
          }
        });
    }
  };

  const handleKeyDown = (event) => {
    if (active) {
      // if this is the backspace key,
      // then prevent the browser from going back a page and logging the user out
      // if (event.keyCode === 8) {
      //   event.preventDefault();
      // }

      // if this is the enter key,
      // then submit the TAPS form to the server
      if (event.keyCode === 13) {
        postScan(scanInput);
        setScanInput({});
      }
      // if this is not the enter key,
      // then convert the key code to a character and
      // append to the other characters in the input element
      else {
        const character = String.fromCharCode(event.keyCode);
        if (isAlphanumeric(character)) {
          let scan = scanInput.scan;
          if (!scan) {
            scan = "";
          }
          setScanInput({ scan: scan + character });
        }
      }
    }
  };

  return null; //<Typography>{scanInput.scan}</Typography>;
};

export default ScanReader;
