//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import MessageDialog, {
  useMessageDialog,
} from "eai-configurator-ui/components/configuration/dialog/MessageDialog";

/**
 * Alternative to StatusBar. Shows a  message in form of a dialog.
 * This dialog is designed for small screens to avoid showing a status message which makes the screen noisy.
 */
const StatusDialog = ({ status }) => {
  const [messageContents, setMessageContents] = useMessageDialog();
  React.useEffect(() => {
    if (status &&
      // Avoid showing status if the dialog parameter is set because in this case another dialog is shown
      !status.dialog
    ) {
      setMessageContents(status.message);
    }
  }, [status]);
  return (
    <MessageDialog
      {...messageContents}
      maxWidth="md"
      success={status && status.success}
      error={status && !status.success}
    />
  );
};
export default StatusDialog;
