//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import * as Utils from "eai-configurator-ui/components/Utils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ActivityTable from "src/components/taps/table/ActivityTable";
import SettingsHeader from "src/components/taps/settings/SettingsHeader";
import "../main.css";
import StatusBar from "src/components/taps/status/StatusBar";
import ClearScansButton from "src/components/taps/buttons/ClearScansButton";
import SubmitScansButton from "src/components/taps/buttons/SubmitScansButton";

const ActivityTablePage = ({ setActionButtons }) => {
  const [
    forceFetchActivitiesCounter,
    setForceFetchActivitiesCounter,
  ] = React.useState(0);
  const [status, setStatus] = useState(null);

  const handleScansCleared = () => {
    setStatus({ message: "" });
    setForceFetchActivitiesCounter(forceFetchActivitiesCounter + 1);
  };

  React.useEffect(() => {
    setActionButtons([
      <ClearScansButton handleScansCleared={handleScansCleared} />,
      <SubmitScansButton
        handleSaveResponse={setStatus}
        fetchActivities={() =>
          setForceFetchActivitiesCounter(forceFetchActivitiesCounter + 1)
        }
      />,
    ]);
    return () => {
      setActionButtons([]);
    };
  }, [forceFetchActivitiesCounter, setStatus]);

  React.useEffect(() => {
    Utils.fetchData("taps/status", null, false, false)
      .then((response) => response.text())
      .then((response) => {
        setStatus({ message: response });
      });
  }, []);

  return (
    <div>
      <SettingsHeader path={"taps"} autoSaveOnEveryEdit />
      <div style={{ marginTop: 2 }}>
        <StatusBar status={status} />
        <ActivityTable
          path={"taps:activities"}
          status={status}
          setStatus={setStatus}
          forceFetchActivities={forceFetchActivitiesCounter}
        />
      </div>
    </div>
  );
};
export default ActivityTablePage;
