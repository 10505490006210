//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";

const NavigateToMainPageButton = ({}) => {
  let history = useHistory();
  const handleClick = () => {
    history.push("/taps");
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      startIcon={<ArrowBackIos />}
    >
      Back
    </Button>
  );
};
export default NavigateToMainPageButton;
