//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import * as Utils from "eai-configurator-ui/components/Utils";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import { useErrorHandler } from "react-error-boundary";

/**
 * Hook which issues a configuration save request.
 */
export function useSaveConfiguration() {
  // Use error handler which will report an error through ErrorBoundary defined up in the component stack
  const handleError = useErrorHandler();

  const issueSaveRequest = (successCallback) => {
    Utils.fetchData("configuration/save", {
      method: "post",
      body: JSON.stringify({
        values: Config.buildConfigurationValueDtos(),
      }),
    })
      .then((data) => {
        if (!data.success) {
          handleError(new Error(data.messages.join(" ")));
        } else {
          if (successCallback) {
            successCallback(data);
          }
        }
      })
      .catch((error) => handleError(new Error("Internal server error")));
  };

  return issueSaveRequest;
}
