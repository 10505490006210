//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import SettingsHeader from "src/components/taps/settings/SettingsHeader";
import ConfigurationAsyncComboBox from "eai-configurator-ui/components/configuration/base/ConfigurationAsyncComboBox";
import SaveConfigurationButton from "eai-configurator-ui/components/configuration/buttons/SaveConfigurationButton";

export default function TapsSettingsMobilePage({ setActionButtons }) {
  React.useEffect(() => {
    setActionButtons([<SaveConfigurationButton label="Save" singleProfile />]);
    return () => {
      setActionButtons([]);
    };
  }, []);
  return (
    <div>
      <SettingsHeader path={"taps"} />
      <ConfigurationAsyncComboBox
        path={"taps:percentages"}
        freeSolo
        multiple
        fullWidth
        textFieldProps={{ type: "number" }}
      />
    </div>
  );
}
