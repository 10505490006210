//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../main.css";
import OutOfSequenceTable from "src/components/taps/table/OutOfSequenceTable";
import CheckCircleIcon from "@material-ui/icons/Check";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
const OutOfSequenceReportPage = ({
  scanSubmissionResponse,
  setScanSubmissionRequest,
}) => {
  const printedOnTimestamp = Config.fromPath(
    "taps:outOfSequenceReport:printedDate",
    true
  ).data.value;
  
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="body1" color="textSecondary">
          <CheckCircleIcon style={{ color: "green" }} />
          <b>
            All activities successfully saved. These activities were out of
            sequence.
          </b>
          <p />
          Printed on {printedOnTimestamp}
        </Typography>
      </div>

      <div>
        <div style={{ marginTop: 20 }}>
          <OutOfSequenceTable path={"taps:outOfSequenceReport:activities"} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default OutOfSequenceReportPage;
