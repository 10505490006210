//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ConfigurationAsyncComboBox from "eai-configurator-ui/components/configuration/base/ConfigurationAsyncComboBox";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";

const FilterByCodeDialog = ({
  path,
  open,
  handleApplyChanges,
  handleClose,
}) => {
  const [text, setText] = useState("");
  const [changeCount, setChangeCount] = useState(0);

  let subjectArea =
    path && path.indexOf("filterByActCode") >= 0 ? "Activity" : "Project";
  return (
    <CloseableDialog
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth
      title={"Filter By " + subjectArea + " Code"}
    >
      <DialogContent>
        <DialogContentText>
          Select {subjectArea} code values to filter by
        </DialogContentText>
        <p />
        <ConfigurationAsyncComboBox
          fullWidth
          path={path + ":name"}
          url={"cloudapi/configuration/codes?subjectArea=" + subjectArea}
          autoOpen
          autoSelect={false}
          textFieldProps={{ autoFocus: true }}
          handleChanged={() => setChangeCount(changeCount + 1)}
        />
        <ConfigurationAsyncComboBox
          fullWidth
          multiple
          path={path + ":values"}
          key={"codeValue" + changeCount}
          getOptionLabel={(option) => option.name}
          transformFromConfig={(values) =>
            values.map((value) => Object({ name: value }))
          }
          transformToConfig={(values) => values.map((value) => value.name)}
          url={() => {
            const name = Config.fromPath(path + ":name");
            if (name) {
              return (
                "cloudapi/configuration/codeValues?subjectArea=" +
                subjectArea +
                "&codeTypeName=" +
                encodeURIComponent(name.data.value)
              );
            }
            return null;
          }}
          autoSelect={false}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleApplyChanges(text)}>
          Apply
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};
export default FilterByCodeDialog;
