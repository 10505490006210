//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Toolbar from "@material-ui/core/Toolbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScanReader from "src/components/taps/scan/ScanReader";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import {convertConfigDataToTableData} from "eai-configurator-ui/components/configuration/table/ConfigurationTableUtils";
import * as TapsUtils from "src/components/taps/table/TapsUtils";
import Select from "@material-ui/core/Select";
import MessageDialog from "eai-configurator-ui/components/configuration/dialog/MessageDialog";
import TapsActivityFilterDialog from "src/components/taps/mobile/filter/TapsActivityFilterDialog";
import ClearScansButton from "src/components/taps/buttons/ClearScansButton";
import StatusDialog from "src/components/taps/status/StatusDialog";
import SubmitScansButton from "src/components/taps/buttons/SubmitScansButton";
import {useSaveConfiguration} from "src/components/taps/save/ConfigurationSaveUtils";
import * as Utils from "eai-configurator-ui/components/Utils";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useTableStyles = makeStyles((theme) => ({
  tableCaption: {
    textAlign: "center",
    marginBottom: 10,
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },
}));

function Selection({
                     dataField,
                     title,
                     row,
                     handleCellEdited,
                     percentageOptions,
                   }) {
  let value = row[dataField.dataField];
  if (dataField.formatter) {
    value = dataField.formatter(value, row);
  }

  const style = dataField.style ? dataField.style(value, row) : null;

  return (
    <TableCell align="right" style={style}>
      <FormControl align="right" style={{width: "100%", margin: 0}}>
        {title && <InputLabel>{title}</InputLabel>}
        <Select
          value={value}
          native
          disableUnderline
          onChange={(event) =>
            handleCellEdited(value, event.target.value, row, dataField)
          }
        >
          <option value={value}>{value}</option>
          {percentageOptions.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
}

function NumberEditor({dataField, title, row, handleCellEdited}) {
  let value = row[dataField.dataField];
  const [editedValue, setEditedValue] = React.useState(value);

  // Update edited value anytime the actual value changes
  React.useEffect(() => setEditedValue(value), [value]);

  if (dataField.formatter) {
    value = dataField.formatter(value, row);
  }

  const style = dataField.style ? dataField.style(value, row) : null;

  return (
    <TableCell style={style}>
      <TextField
        style={{margin: 0}}
        id="num-edit"
        label={title}
        type="number"
        value={editedValue}
        onBlur={() => handleCellEdited(value, editedValue, row, dataField)}
        onChange={(event) => setEditedValue(event.target.value)}
        inputProps={{inputMode: "decimal"}}
      />
    </TableCell>
  );
}

/**
 * Returns a row with the activity and the edit options of it
 */
function Row({row, dataFields, handleCellEdited, percentageOptions}) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  // The '% Complete' option should automatically correspond to the percent complete type
  // defined in 'actPercentCompleteType' config (duration, physical or units)
  let percentCompleteTypeColumn = dataFields.find(
    (it) => it.dataField === "actPercentCompleteType"
  );
  let percentCompleteType = row[percentCompleteTypeColumn.dataField];
  if (percentCompleteTypeColumn.formatter) {
    percentCompleteType = percentCompleteTypeColumn.formatter(
      percentCompleteType,
      row
    );
  }

  // Use the right config (durationPercentComplete, physicalPercentComplete or unitsPercentComplete)
  const percentCompleteTypeConfigKey =
    percentCompleteType.toLowerCase() + "PercentComplete";
  const percentCompleteTypeConfig = dataFields.find(
    (it) => it.dataField === percentCompleteTypeConfigKey
  );

  return (
    <React.Fragment>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.actId}
        </TableCell>
        <TableCell align="right">{row.actName}</TableCell>
      </TableRow>
      <TableRow className={classes.root} colSpan={3}>
        <TableCell align="right" component="th" scope="row"></TableCell>
        <NumberEditor
          title={"RD"}
          dataField={dataFields.find(
            (dataField) => dataField.dataField === "remainingDuration"
          )}
          row={row}
          handleCellEdited={handleCellEdited}
        />
        {percentCompleteTypeConfig && (
          <Selection
            title={"% Complete"}
            dataField={percentCompleteTypeConfig}
            row={row}
            handleCellEdited={handleCellEdited}
            percentageOptions={[
              ...percentageOptions,
              ...["COMPLETE", "CANCEL"],
            ]}
          />
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#f7f7f7",
          }}
          colSpan={3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {dataFields.map((dataField, index) => {
                    if (
                      dataField.hidden ||
                      dataField.dataField === "actId" ||
                      dataField.dataField === "actName"
                    ) {
                      return null;
                    }
                    let value = row[dataField.dataField];
                    if (dataField.formatter) {
                      value = dataField.formatter(value, row, index);
                    }
                    const style = dataField.style
                      ? dataField.style(value, row)
                      : null;
                    return (
                      <TableRow key={dataField.key}>
                        <TableCell component="th" scope="row" align="left">
                          <b>{dataField.text}</b>
                        </TableCell>
                        {dataField.editable ? (
                          dataField.dataField === "remainingDuration" ? (
                            <NumberEditor
                              dataField={dataField}
                              row={row}
                              handleCellEdited={handleCellEdited}
                            />
                          ) : (
                            <Selection
                              dataField={dataField}
                              row={row}
                              handleCellEdited={handleCellEdited}
                              percentageOptions={percentageOptions}
                            />
                          )
                        ) : (
                          <TableCell style={style}>{value}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({setActionButtons}) {
  const classes = useTableStyles();
  const [status, setStatus] = React.useState(null);
  const [filterOpen, setFilterOpen] = React.useState(false);

  const saveConfiguration = useSaveConfiguration();

  const handleSaveResponse = (response) => {
    setStatus(response);
    fetchActivities();
  };

  const [manuallyEditedCellScan, setManuallyEditedCellScan] = React.useState(
    null
  );

  const [
    configurationRequestPending,
    setConfigurationRequestPending,
  ] = React.useState(false);

  const [scanRequestPending, setScanRequestPending] = React.useState(false);

  React.useEffect(() => {
    setActionButtons([
      <ClearScansButton
        title={"Clear Changes"}
        handleScansCleared={handleScansCleared}
      />,
      <SubmitScansButton
        handleSaveResponse={handleSaveResponse}
        fetchActivities={fetchActivities}
      />,
    ]);
    return () => {
      setActionButtons([]);
    };
  }, []);

  const handleScansCleared = () => {
    fetchActivities();
  };

  const path = "taps:activities";

  const handleCellEdited = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      const activityMarker = "O";
      const scan =
        newValue === "COMPLETE" || newValue === "CANCEL"
          ? newValue
          : TapsUtils.markerByField[column.dataField] + newValue;
      setManuallyEditedCellScan({
        scan: scan,
        objectId: activityMarker + row.objectId,
      });
    }
  };

  const fetchActivities = () => {
    if (!configurationRequestPending) {
      setConfigurationRequestPending(true);
      Utils.fetchData("configuration/values")
        .then((data) => {
          Config.setConfigurationValues(data.values, data.defaults);
          setConfigurationRequestPending(false);
        })
        .catch(function (response) {
          setConfigurationRequestPending(false);
          Utils.getErrorMessage(response, "Connection failed.").then(
            (message) =>
              setStatus({
                success: false,
                message: message,
              })
          );
        });
    }
  };

  const issueSaveRequest = () => {
    if (!configurationRequestPending) {
      setConfigurationRequestPending(true);

      saveConfiguration(fetchActivities);
    }
  };

  const columns = TapsUtils.createActivityColumns();

  const handleScanRequestIssued = (scanRequest) => {
    setScanRequestPending(true);
  };

  const onScanResponseReceived = (scanResponse) => {
    setScanRequestPending(false);

    // Avoid showing successful status updates - it's too noisy and unnecessary on mobile
    if (scanResponse && !scanResponse.success) {
      setStatus(scanResponse);
    }
    fetchActivities();
  };

  const issueEnableFiltersRequest = () => {
    Utils.fetchData("taps/enableFilters", null, false, false).then(
      issueSaveRequest
    );
  };

  const handleFilterApplied = () => {
    issueEnableFiltersRequest();
    setFilterOpen(false);
  };

  const handleFilterCancelled = () => {
    fetchActivities();
    setFilterOpen(false);
  };

  const percentagesConfig = Config.fromPath("taps:percentages", true);

  const percentageOptions = percentagesConfig.data.value;

  const rows = convertConfigDataToTableData(path, columns);

  const isEmpty = rows.length === 0;

  return (
    <div>
      <StatusDialog status={status}/>
      <MessageDialog
        handleClose={handleFilterCancelled}
        handleOk={handleFilterApplied}
        maxWidth="md"
        fullWidth
        title={"Search Activities"}
        open={filterOpen}
        okButtonLabel={"Apply"}
        hideCloseButton
        description={
          <div>
            <Typography variant="body2" color="textPrimary">
              Filter out activities by project, activity code etc.
            </Typography>
            <p/>
            <TapsActivityFilterDialog/>
          </div>
        }
      />
      <ScanReader
        active
        handleScanRequestIssue={handleScanRequestIssued}
        onScanResponseReceived={onScanResponseReceived}
        manualScan={manuallyEditedCellScan}
      />
      <Toolbar className={classes.toolbarRoot}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Activities
          {(configurationRequestPending || scanRequestPending) && (
            <CircularProgress
              color="inherit"
              style={{marginLeft: "10px"}}
              size={20}
            />
          )}
        </Typography>
        <IconButton
          aria-label="filter list"
          onClick={() => setFilterOpen(true)}
        >
          <FilterListIcon/>
        </IconButton>
      </Toolbar>

      {isEmpty ? (
        <Typography
          className={classes.tableCaption}
          variant="body2"
          color={"textSecondary"}
        >
          <hr/>
          Configure a filter to view activities
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {rows.map((row) => (
                <Row
                  key={row.actId}
                  row={row}
                  dataFields={columns}
                  handleCellEdited={handleCellEdited}
                  percentageOptions={percentageOptions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
