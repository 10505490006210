//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  createDataIndexEditorColumn,
  createTextEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ConfigurationTable from "eai-configurator-ui/components/configuration/table/ConfigurationTable";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import { isMobile } from "react-device-detect";
import "../main.css";

const OutOfSequenceTable = ({ path }) => {
  const activityPath = `${path}:activity`;
  const createTextColumn = (key, searchVisible, props) => {
    return createTextEditorColumn(`${activityPath}:${key}`, searchVisible, {
      editable: false,
      ...props,
    });
  };
  let columns = [
    createTextColumn("scannedIn"),
    createTextColumn("projId"),
    createTextColumn("actId"),
    createTextColumn("description"),
    createTextColumn("activityBarcode", null, {
      headerStyle: { width: "25%" },
      align: "center",
      classes: "barcode_container, barcode",
    }),
    createDataIndexEditorColumn(),
  ];
  if (isMobile) {
    const hiddenDataFields = ["projId", "description", "activityBarcode"];
    columns = columns.filter(
      (column) => !hiddenDataFields.includes(column.dataField)
    );
  }

  const title = Config.getDefault(path).data.label;
  return (
    <div style={{ width: "100%" }}>
      <ConfigurationTable
        path={path}
        childKey={"activity"}
        columns={columns}
        title={title}
        hideAddButton
        hideFilterButton
      />
    </div>
  );
};

export default OutOfSequenceTable;
