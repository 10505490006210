//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SortByFieldDialog from "src/components/taps/mobile/sort/dialog/SortByFieldDialog";
import SortByCodeDialog from "src/components/taps/mobile/sort/dialog/SortByCodeDialog";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    whiteSpace: "nowrap",
  },
  inputCenter: {
    textAlign: "center",
    color: "red",
  },
}));

const getSortConditionSummary = (config) => {
  let value;
  if (config.key === "sortByField") {
    const fieldConfig = config.children.find(
      (grandchild) => grandchild.key === "field"
    );
    if (fieldConfig && fieldConfig.data.value) {
      value = fieldConfig.data.valueMap[fieldConfig.data.value];
    }
  } else if (
    config.key === "sortByProjCode" ||
    config.key === "sortByActCode"
  ) {
    const codeNameConfig = config.children.find(
      (grandchild) => grandchild.key === "name"
    );
    if (codeNameConfig && codeNameConfig.data.value) {
      value = codeNameConfig.data.value;
    }
  }
  if (value) {
    const reverse = config.children.find(
      (grandchild) => grandchild.key === "reverse"
    );
    if (String(reverse.data.value) === "true") {
      value += " (reversed)";
    }
  }
  return value;
};
const getDefinedOptions = (path) => {
  const config = Config.getDefault(path + ":sort");
  return config.children.map((child) => {
    const option = {
      id: child.key,
      key: child.key,
      label: child.data.label,
    };
    return option;
  });
};
const getConfiguredOptions = (path) => {
  const config = Config.fromPath(path);
  if (!config) {
    return [];
  }
  const definedOptions = getDefinedOptions(path);
  return config.children
    .map((child) => {
      if (child.children.length === 1) {
        child = child.children[0];
        const sortConditionSummary = getSortConditionSummary(child);
        if (sortConditionSummary) {
          const definition = definedOptions.find(
            (option) => option.id === child.key
          );
          const option = { ...definition };
          option.value = child.data.value;
          option.label = sortConditionSummary;
          option.config = child;
          return option;
        }
      }
      return null;
    })
    .filter((option) => option !== null);
};

function SortInputField({
  path,
  handlePatternChange,
  pattern: initialPattern,
  isInsideGrid,
}) {
  const classes = useStyles();
  const [
    isPendingCustomizationOption,
    setIsPendingCustomizationOption,
  ] = React.useState(false);
  const [renderFlag, setRenderFlag] = React.useState(false);
  const pendingCustomizationOption = React.useRef(null);

  // useEffect(() => {
  //   if (!isPendingCustomizationOption) {
  //     const pattern = selectedOptions.current
  //       .map((option) => option.getPattern())
  //       .join("");
  //     if (handlePatternChange) {
  //       handlePatternChange(pattern);
  //     }
  //   }
  // }, [isPendingCustomizationOption, renderFlag, handlePatternChange]);

  const onChange = (event, values, reason) => {
    if (reason === "select-option") {
      const selectedValue = values[values.length - 1];

      const newItem = Config.getDefault(`${path}:sort`);
      newItem.children = newItem.children.filter(
        (child) => child.key === selectedValue.key
      );
      const parent = Config.fromPath(path, false, true);
      Config.addChild(parent, newItem, false);

      pendingCustomizationOption.current = newItem.children[0];
      setIsPendingCustomizationOption(true);
    } else if (reason === "remove-option") {
      const remainingSorts = values.map((value) => value.config.parent);
      const config = Config.fromPath(path);
      for (let i = config.children.length - 1; i >= 0; i--) {
        if (!remainingSorts.includes(config.children[i])) {
          Config.deleteChild(path, i);
        }
      }
    } else if (reason === "clear") {
      const config = Config.fromPath(path);
      for (let i = config.children.length - 1; i >= 0; i--) {
        Config.deleteChild(path, i);
      }
    }
    forceRender();
  };

  const forceRender = () => {
    setRenderFlag(!renderFlag);
  };

  const handleApplyChanges = () => {
    setIsPendingCustomizationOption(false);
  };

  const handleCancelChanges = () => {
    // Delete last configuration child
    const config = Config.fromPath(path);
    if (config && config.children.length > 0) {
      Config.deleteChild(path, config.children.length - 1);
    }
    setIsPendingCustomizationOption(false);
  };

  const definedOptions = getDefinedOptions(path);
  const configuredOptions = getConfiguredOptions(path);
  const customizationPath = pendingCustomizationOption.current
    ? Config.toPath(pendingCustomizationOption.current)
    : null;
  return (
    <div className={classes.root}>
      <SortByFieldDialog
        path={customizationPath}
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.key === "sortByField"
        }
        handleApplyChanges={handleApplyChanges}
        handleClose={handleCancelChanges}
      />
      <SortByCodeDialog
        path={customizationPath}
        open={
          (isPendingCustomizationOption &&
            pendingCustomizationOption.current.key === "sortByProjCode") ||
          (isPendingCustomizationOption &&
            pendingCustomizationOption.current.key === "sortByActCode")
        }
        handleApplyChanges={handleApplyChanges}
        handleClose={handleCancelChanges}
      />
      <Autocomplete
        multiple
        id="sort-order"
        filterOptions={(options, state) => {
          return definedOptions.map((option) => ({ ...option }));
        }}
        value={configuredOptions}
        options={definedOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label="Sort By..."
            placeholder={isInsideGrid ? "" : "AND..."}
            helperText={"Tap to define sorting order"}
          />
        )}
      />
    </div>
  );
}

export default SortInputField;
