//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SaveConfigurationButton from "eai-configurator-ui/components/configuration/buttons/SaveConfigurationButton";
import ErrorMessageDialog, {
  useErrorMessageDialog,
} from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";
import * as Utils from "eai-configurator-ui/components/Utils";
import { useHistory } from "react-router-dom";

const SubmitScansButton = ({ handleSaveResponse, fetchActivities }) => {
  let history = useHistory();
  const [scanSubmissionRequest, setScanSubmissionRequest] = React.useState({});
  const [scanSubmissionResponse, setScanSubmissionResponse] = React.useState(
    null
  );
  const [errorMessageTitle, setErrorMessageTitle] = useState(null);
  const [
    errorMessageContents,
    setErrorMessageContents,
  ] = useErrorMessageDialog();
  React.useEffect(() => {
    if (scanSubmissionResponse) {
      handleSaveResponse(scanSubmissionResponse);
      if (scanSubmissionResponse.dialog) {
        setErrorMessageTitle(scanSubmissionResponse.dialog.title);
        setErrorMessageContents(scanSubmissionResponse.dialog.details);
      } else if (
        scanSubmissionResponse.success &&
        scanSubmissionResponse.outOfSequenceActivitiesFound
      ) {
        history.push("/out-of-sequence");
      }

      // Reset/clear request parameters
      setScanSubmissionRequest({
        userAcknowledgedWarnings: false,
      });
      setScanSubmissionResponse(null);
      fetchActivities();
    }
  }, [scanSubmissionResponse]);

  const handleAcknowledgeWarning = () => {
    setScanSubmissionRequest({
      userAcknowledgedWarnings: true,
    });
    setErrorMessageContents(null);
  };
  const handleResponse = (response) => {
    setScanSubmissionResponse(response);
  };
  return (
    <div>
      <ErrorMessageDialog
        title={errorMessageTitle}
        {...errorMessageContents}
        maxWidth="md"
        htmlContents
        handleOk={handleAcknowledgeWarning}
        closeButtonLabel={"CANCEL"}
      />
      <SaveConfigurationButton
        label="Save"
        singleProfile
        handleResponse={handleResponse}
        url={`taps/saveProgress?request=${JSON.stringify(
          scanSubmissionRequest
        )}`}
        forceClick={Boolean(
          scanSubmissionRequest &&
            scanSubmissionRequest.userAcknowledgedWarnings
        )}
      />
    </div>
  );
};
export default SubmitScansButton;
