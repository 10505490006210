//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ConfigurationComboBox from "eai-configurator-ui/components/configuration/base/ConfigurationComboBox";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";

const SortByFieldDialog = ({ path, open, handleApplyChanges, handleClose }) => {
  const [text, setText] = useState("");

  return (
    <CloseableDialog
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth
      title={"Sort By Activity Field"}
    >
      <DialogContent>
        <DialogContentText>
          Select activities alphabetically by selected field
        </DialogContentText>
        <p />
        <ConfigurationComboBox fullWidth path={path + ":field"} />
        <ConfigurationCheckBox path={path + ":reverse"} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleApplyChanges(text)}>
          Apply
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};
export default SortByFieldDialog;
