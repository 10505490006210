//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import ReactDOM from "react-dom";
import TapsApplication from "src/components/taps/TapsApplication";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return <TapsApplication />;
}

ReactDOM.render(<App />, document.getElementById("root"));
