//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import * as Utils from "eai-configurator-ui/components/Utils";

const ClearScansButton = ({ title, handleScansCleared }) => {
  const handleClick = () => {
    issueClearScansRequest();
  };
  const issueClearScansRequest = () => {
    Utils.fetchData("taps/clearScans", { method: "POST" }, false, false)
      .then((data) => {
        handleScansCleared();
      })
      .catch(function (error) {
        // Ignore
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      startIcon={<ClearAllIcon />}
    >
      {title ? title : "Clear Scans"}
    </Button>
  );
};
export default ClearScansButton;
