//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createCheckBoxEditorColumn,
  createDataIndexEditorColumn,
  createTextEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ConfigurationTable from "eai-configurator-ui/components/configuration/table/ConfigurationTable";
import "../main.css";
import * as TapsUtils from "src/components/taps/table/TapsUtils";

const ActivityStepTable = ({
                             path,
                             setIsEditing,
                             setManuallyEditedCellScan,
                           }) => {
  const [searchVisible, setSearchVisible] = React.useState(false);

  const stepPath = `${path}:steps:step`;
  const columns = [
    createTextEditorColumn(stepPath + ":name", searchVisible, {
      editable: false,
    }),
    createTextEditorColumn(stepPath + ":weight", searchVisible, {
      editable: false,
    }),
    createTextEditorColumn(stepPath + ":percentComplete", searchVisible, {
      style: TapsUtils.percentCompleteStyle,
      align: "right",
    }),
    createTextEditorColumn(stepPath + ":startDate", searchVisible),
    createTextEditorColumn(stepPath + ":endDate", searchVisible),
    createCheckBoxEditorColumn(stepPath + ":completed", searchVisible, {
      editable: false,
      align: "right",
      style: TapsUtils.completedStyle,
    }),
    createCheckBoxEditorColumn(stepPath + ":cancelled", searchVisible, {
      editable: false,
      align: "right",
      style: TapsUtils.cancelledStyle,
    }),
    createDataIndexEditorColumn(),
    {
      dataField: "objectId",
      hidden: true,
      type: "text",
    },
    {
      dataField: "modifiedFields",
      hidden: true,
    },
    {
      dataField: "active",
      hidden: true,
    },
  ];

  const handleStartCellEditing = (row, column, rowIndex, columnIndex) => {
    setIsEditing(true);
  };

  const handleEndCellEditing = (oldValue, newValue, row, column) => {
    setIsEditing(false);

    if (oldValue !== newValue) {
      const stepMarker = "S";
      setManuallyEditedCellScan({
        scan: TapsUtils.markerByField[column.dataField] + newValue,
        objectId: stepMarker + row.objectId,
      });
    }
  };
  return (
    <div style={{width: "100%"}}>
      <ConfigurationTable
        path={`${path}:steps`}
        childKey={"step"}
        columns={columns}
        onStartEdit={handleStartCellEditing}
        afterSaveCell={handleEndCellEditing}
        hideAddButton
        rowStyle={TapsUtils.activityRowStyle}
        hideTableWhenEmpty
      />
    </div>
  );
};

export default ActivityStepTable;
